<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <el-page-header @back="$router.go(-1)" content="销售数据"></el-page-header>
    <div class="content-box">
      <el-tabs>
        <el-tab-pane label="销售数据">
          <el-row class="search-box">
            <el-row class="search-row">
              <el-row class="search-item">
                <label>选择时间：</label>
                <date-packer :datePacker="salesDateRange" @setDatePacker="getSalesDatePacker"></date-packer>
              </el-row>
              <el-button @click="handleSalesQuery(true)" class="bg-gradient" type="primary" icon="el-icon-search">搜索</el-button>
              <el-button @click="handleSalesQuery(false)" icon="el-icon-refresh-right" plain>重置</el-button>
            </el-row>
          </el-row>
          <el-table :loading="salesLoad" :data="salesData" :stripe="true" border fit>
            <el-table-column label="序号">
              <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column prop="dateStr" label="日期"></el-table-column>
            <el-table-column prop="totalAmount" label="销售额"></el-table-column>
            <el-table-column prop="orderNumber" label="订单数量"></el-table-column>
            <el-table-column prop="userNumber" label="分销人数"></el-table-column>
            <el-table-column prop="awardAmount" label="佣金收入"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button @click="goToDetail(scope.row.dateStr, 'sales')" type="text">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <pagination :total="salesTotal" :page-size="salesLimit" @handleSizeChangeSub="changeSalesPageNum" @handleCurrentChangeSub="handleSalesCurrPage"/>
        </el-tab-pane>
        <el-tab-pane label="核销数据">
          <el-row class="search-box">
            <el-row class="search-row">
              <el-row class="search-item">
                <label>选择时间：</label>
                <date-packer :datePacker="salesDateRange" @setDatePacker="getWriteOffDatePacker"></date-packer>
              </el-row>
              <el-button @click="handleWriteOffQuery(true)" class="bg-gradient" type="primary" icon="el-icon-search">搜索</el-button>
              <el-button @click="handleWriteOffQuery(false)" icon="el-icon-refresh-right" plain>重置</el-button>
            </el-row>
          </el-row>
          <el-table :loading="writeOffLoad" :data="writeOffData" :stripe="true" border fit>
            <el-table-column label="序号">
              <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column prop="dateStr" label="日期"></el-table-column>
            <el-table-column prop="totalAmount" label="核销金额"></el-table-column>
            <el-table-column prop="orderNumber" label="核销订单数"></el-table-column>
            <el-table-column prop="userNumber" label="核销人数"></el-table-column>
            <el-table-column prop="awardAmount" label="核销佣金"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button @click="goToDetail(scope.row.dateStr, 'writeOff')" type="text">详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <pagination :total="writeOffTotal" :page-size="writeOffLimit" @handleSizeChangeSub="changeWriteOffPageNum" @handleCurrentChangeSub="handleWriteOffCurrPage"/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { getMarketingStat, getMarketingUseStat } from '@/api/shop/distribution/distribution';
import { mapState } from 'vuex'
export default {
  data () {
    return {
      crumbs: new Map([
        ['特惠商城'], ['分销中心'], ['渠道分销', '/channel_distribution'], ['销售数据']
      ]),
      salesDateRange: [],
      salesLimit: 1,
      salesPage: 1,
      salesData: [],
      salesTotal: 0,
      salesLoad: true,
      writeOffLoad: true,
      writeOffData: [],
      writeOffLimit: 1,
      writeOffPage: 1,
      writeOffDateRange: [],
      writeOffTotal: 0
    }
  },
  computed: {
    ...mapState(['hotelInfo'])
  },
  mounted() {
    this.writeOffLimit = this.salesLimit = sessionStorage.getItem('pageSize') * 1;
    this.getSalesData()
    this.getWriteOffData()
  },
  methods: {
    // 获取销售数据
    getSalesData() {
      const query = `?limit=${ this.salesLimit }&page=${ this.salesPage }`,
            params = {
              beginDateStr: this.salesDateRange[0],
              endDateStr: this.salesDateRange[1],
              hotelId: this.hotelInfo.id
            };
      getMarketingStat(query, params).then(({ success, records, total }) => {
        if (!success) return;
        this.salesLoad = false
        this.salesData = records
        this.salesTotal = total
      })
    },
    // 改变销售每页数
    changeSalesPageNum(num) {
      this.salesLimit = num;
      this.getSalesData()
    },
    // 改变销售当前页
    handleSalesCurrPage(num) {
      this.salesPage = num
      this.getSalesData()
    },
    // 查询销售数据
    handleSalesQuery(bool) {
      this.salesPage = 1
      if (bool) return this.getSalesData()
      this.salesDateRange = []
    },
    // 获取销售时间段
    getSalesDatePacker(val) {
      this.salesDateRange = val
    },
    // 获取核销数据
    getWriteOffData() {
      const query = `?limit=${ this.writeOffLimit }&page=${ this.writeOffPage }`,
            params = {
              beginDateStr: this.writeOffDateRange[0],
              endDateStr: this.writeOffDateRange[1],
              hotelId: this.hotelInfo.id
            };
      getMarketingUseStat(query, params).then(({ success, records, total }) => {
        if (!success) return;
        this.writeOffLoad = false
        this.writeOffData = records
        this.writeOffTotal = total
      })
    },
    // 改变核销每页数
    changeWriteOffPageNum(num) {
      this.writeOffLimit = num;
      this.getWriteOffData()
    },
    // 改变核销当前页
    handleWriteOffCurrPage(num) {
      this.writeOffPage = num
      this.getWriteOffData()
    },
    // 查询核销数据
    handleWriteOffQuery(bool) {
      this.writeOffPage = 1
      if (bool) return this.getWriteOffData()
      this.writeOffDateRange = []
    },
    // 获取核销时间段
    getWriteOffDatePacker(val) {
      this.salesDateRange = val
      this.getWriteOffData()
    },
    // 去详情页
    goToDetail(dateStr, from) {
      this.$router.push({ path: '/channel_distribution/statistics/detail', query: { dateStr, from } })
    }
  },
  watch: {
    hotelInfo(newVal, oldVal) {
      if (oldVal && newVal.id) {
        this.getSalesData()
        this.getWriteOffData()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.cont {
  .content-box {
    padding-top: 20px;
    ::v-deep .el-tabs__header{ margin-bottom: 40px }
  }
}
</style>
